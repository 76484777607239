'use client';
import { resetPassword } from '@aws-amplify/auth';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { ResetPasswordOutput, signOut } from 'aws-amplify/auth';
import React, { useCallback } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { SubmitButton } from '#/packages/authentication/components/SubmitButton';
import { Alert, FormField, TextInput } from '#/packages/ui';
import { NavAnchor } from '#/src/components/NavAnchor';

interface ForgotPasswordFormProps {
  username?: string;
  setUsername: (v: string) => void;
  onNext: (output: ResetPasswordOutput) => void;
}

export default function ResetPasswordForm(props: ForgotPasswordFormProps) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm();

  const onSubmit = useCallback(async (data: FieldValues) => {
    try {
      const res = await resetPassword({ username: data.email });
      props.setUsername(data.email);
      props.onNext(res);
    } catch (e: any) {
      if (e.name == 'UserAlreadyAuthenticatedException') {
        await signOut();
        onSubmit(data);
      }
      setError('root.apiError', { message: e.message });
    }
  }, []);

  return (
    <form className='space-y-6 mt-4' onSubmit={handleSubmit(onSubmit)}>
      <NavAnchor size={'xs'} color='primary' href='/login'>
        <ArrowLeftIcon className='h-4 w-4 mr-1' aria-hidden='true' /> Back to login
      </NavAnchor>
      <FormField label={'Email address'} id={'email'}>
        <TextInput
          id='email'
          color={'default'}
          {...register('email', { required: true })}
          type='email'
          autoComplete='email'
          placeholder={'Enter email'}
          required
        />
      </FormField>
      {errors.root?.apiError && <Alert>{errors?.root?.apiError?.message?.toString()}</Alert>}
      <SubmitButton isLoading={isSubmitting} rounded>
        {isSubmitting ? 'Submitting...' : 'Reset password'}
      </SubmitButton>
    </form>
  );
}
