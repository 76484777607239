'use client';
import { SignUpOutput, signInWithRedirect, signOut, signUp } from 'aws-amplify/auth';
import React, { ReactNode, useCallback } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { AuthProvider, useAnalytics } from '#/packages/analytics';
import { FacebookButton } from '#/packages/authentication/components/FacebookButton';
import { GoogleButton } from '#/packages/authentication/components/GoogleButton';
import { Separator } from '#/packages/authentication/components/Separator';
import { SubmitButton } from '#/packages/authentication/components/SubmitButton';
import { Alert, FormField, TextInput } from '#/packages/ui';

interface SignupFormProps {
  nextUrl?: string;
  source?: string;
  defaultValues?: { email: string };
  username?: string;
  setUsername: (v: string) => void;
  bottom?: ReactNode;

  onNext(result: SignUpOutput): void;
}

export function SignupForm(props: SignupFormProps) {
  const { track } = useAnalytics();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: { email: props.username, name: '', password: '' }
  });

  const onSubmit = useCallback(async (data: FieldValues) => {
    try {
      const [firstName, ...lastNameParts] = data.name.split(' ');
      const lastName = lastNameParts.join(' ');
      const isWorkEmailRet = await (
        await fetch('/api/is-work-email', {
          method: 'POST',
          body: JSON.stringify({ email: data.email })
        })
      ).json();

      if (!isWorkEmailRet.ok) {
        setError('email', { type: 'notWorkEmail' });
        await track('auth.signup.error', {
          reason: 'notWorkEmail'
        });
        return;
      }

      const result = await signUp({
        username: data.email,
        password: data.password,
        options: {
          userAttributes: {
            email: data.email, // optional
            given_name: firstName, // optional - E.164 number convention
            family_name: lastName
          },
          autoSignIn: true
        }
      });
      props.setUsername(data.email);
      props.onNext(result);
    } catch (e: any) {
      if (e.name == 'UserAlreadyAuthenticatedException') {
        await signOut();
        onSubmit(data);
      } else {
        console.error(e);
        setError('root.apiError', { message: e.message });
        await track('auth.signup.error', {
          reason: 'apiError',
          message: e.message
        });
      }
    }
  }, []);

  return (
    <div>
      <div className={'space-y-4'}>
        <GoogleButton
          onClick={() => {
            track('auth.signup.provider', {
              provider: AuthProvider.GOOGLE
            });
            signInWithRedirect({
              provider: 'Google',
              customState: JSON.stringify({
                nextUrl: props.nextUrl,
                source: props.source,
                provider: AuthProvider.GOOGLE
              })
            });
          }}
          className='rounded-full'
        />
        <FacebookButton
          onClick={() => {
            track('auth.signup.provider', {
              provider: AuthProvider.FACEBOOK
            });
            signInWithRedirect({
              provider: 'Facebook',
              customState: JSON.stringify({
                nextUrl: props.nextUrl,
                source: props.source,
                provider: AuthProvider.FACEBOOK
              })
            });
          }}
          className='rounded-full'
        />
        <Separator noLine />
        <form
          data-source={props.source}
          className='flex flex-col gap-y-4 mt-2'
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormField
            id={'email'}
            label={'Email'}
            errors={[
              errors.email?.type === 'required' && 'Email is required',
              errors.email?.type === 'pattern' && 'Not an email',
              errors.email?.type === 'notWorkEmail' && 'Please use your work email'
            ]}
          >
            <TextInput
              id='email'
              type='email'
              color={'default'}
              placeholder={'Enter your email address'}
              {...register('email', {
                required: true,
                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
              })}
              autoComplete='email'
            />
          </FormField>

          <FormField
            id={'name'}
            label={'Name'}
            errors={[errors.name && 'Require first and last name']}
          >
            <TextInput
              color={'default'}
              id='name'
              placeholder={'Enter your first and last name'}
              {...register('name', {
                required: true,
                pattern: /^[^\s]+(\s[^\s]+){1,}$/
              })}
              type='text'
              autoComplete='name'
            />
          </FormField>

          <FormField
            id={'password'}
            label={'Password'}
            errors={[
              errors.password?.type === 'required' && 'Password is required',
              errors.password?.type === 'minLength' && 'Min length is 8'
            ]}
          >
            <TextInput
              color={'default'}
              id='password'
              type='password'
              placeholder={'Enter your password'}
              {...register('password', {
                required: true,
                minLength: 8
              })}
            />
          </FormField>

          {errors?.root?.apiError ? (
            <Alert>{errors?.root?.apiError?.message?.toString()}</Alert>
          ) : null}

          <SubmitButton isLoading={isSubmitting} id={'signup-btn'} rounded>
            {isSubmitting ? 'Creating...' : 'Create account'}
          </SubmitButton>
        </form>
      </div>

      {props.bottom}
    </div>
  );
}
