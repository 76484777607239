'use client';
import {
  ConfirmSignUpOutput,
  ResetPasswordOutput,
  SignInOutput,
  SignUpOutput
} from 'aws-amplify/auth';
import React, { ReactNode, useState } from 'react';
import { AuthenticationFlowType } from '#/packages/authentication/components/AuthenticationFlowType';
import { AuthAutoSigninStep } from '#/packages/authentication/components/steps/AuthAutoSigninStep';
import AuthDoneStep from '#/packages/authentication/components/steps/AuthDoneStep';
import ConfirmResetPasswordAndSignInForm from '#/packages/authentication/components/steps/ConfirmResetPasswordAndSignInForm';
import ConfirmSignupForm from '#/packages/authentication/components/steps/ConfirmSignupForm';
import { LoginForm } from '#/packages/authentication/components/steps/LoginForm';
import ResetPasswordForm from '#/packages/authentication/components/steps/ResetPasswordForm';
import { SignupForm } from '#/packages/authentication/components/steps/SignupForm';

interface AuthenicationFlowProps {
  type: AuthenticationFlowType;
  initialValues: any;
  nextUrl?: string;
  loginBottom?: ReactNode;
  signupBottom?: ReactNode;
  source?: string;
  currentStep?: CurrentStep;
}

export enum CurrentStep {
  SIGNUP = 'SIGNUP',
  LOGIN = 'LOGIN',
  RESET_PASSWORD = 'RESET_PASSWORD',
  CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
  CONFIRM_RESET_PASSWORD_WITH_CODE = 'CONFIRM_RESET_PASSWORD_WITH_CODE',
  CONFIRM_SIGN_UP = 'CONFIRM_SIGN_UP',
  COMPLETE_AUTO_SIGN_IN = 'COMPLETE_AUTO_SIGN_IN',
  DONE = 'DONE'
}

function getFirstStep(type: AuthenticationFlowType): CurrentStep {
  if (type == AuthenticationFlowType.SIGNUP) {
    return CurrentStep.SIGNUP;
  } else if (type == AuthenticationFlowType.LOGIN) {
    return CurrentStep.LOGIN;
  } else if (type == AuthenticationFlowType.RESET_PASSWORD) {
    return CurrentStep.RESET_PASSWORD;
  }
  throw Error('AuthenticationFlowType is not valid');
}

export default function AuthenticationFlow(props: AuthenicationFlowProps) {
  const [currentStep, setCurrentStep] = useState<CurrentStep>(
    props.currentStep || getFirstStep(props.type)
  );
  const [username, setUsername] = useState<string>(props.initialValues?.email);
  const nextUrl = props.nextUrl || '/';
  const source = props.source;

  function onLoginNext(output: SignInOutput) {
    const nextStep = output.nextStep.signInStep as CurrentStep;
    setCurrentStep(nextStep);
  }

  function onSignUpNext(output: SignUpOutput | ConfirmSignUpOutput) {
    const nextStep = output.nextStep.signUpStep as CurrentStep;
    setCurrentStep(nextStep);
  }

  function onPasswordResetNext(output: ResetPasswordOutput) {
    const nextStep = output.nextStep.resetPasswordStep as CurrentStep;
    setCurrentStep(nextStep);
  }

  return (
    <>
      {currentStep == CurrentStep.LOGIN && (
        <LoginForm
          username={username}
          setUsername={setUsername}
          nextUrl={nextUrl}
          source={source}
          onNext={onLoginNext}
          bottom={props.loginBottom}
        />
      )}
      {currentStep == CurrentStep.SIGNUP && (
        <SignupForm
          username={username}
          setUsername={setUsername}
          nextUrl={nextUrl}
          source={source}
          onNext={onSignUpNext}
          bottom={props.signupBottom}
        />
      )}
      {currentStep == CurrentStep.RESET_PASSWORD && (
        <ResetPasswordForm
          username={username!}
          setUsername={setUsername}
          onNext={onPasswordResetNext}
        />
      )}
      {currentStep == CurrentStep.CONFIRM_RESET_PASSWORD_WITH_CODE && (
        <ConfirmResetPasswordAndSignInForm username={username!} onNext={onLoginNext} />
      )}
      {currentStep == CurrentStep.CONFIRM_SIGN_UP && (
        <ConfirmSignupForm username={username!} shouldResend={false} onNext={onSignUpNext} />
      )}
      {currentStep == CurrentStep.COMPLETE_AUTO_SIGN_IN && (
        <AuthAutoSigninStep onNext={onLoginNext} />
      )}
      {currentStep == CurrentStep.DONE && (
        <AuthDoneStep nextUrl={nextUrl} type={props.type} source={source} />
      )}
    </>
  );
}
