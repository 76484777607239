import React from 'react';
import { Button, ButtonProps, classNames } from '#/packages/ui';

export const SubmitButton = (props: ButtonProps & { rounded?: boolean }) => {
  const { rounded = false, ...rest } = props;

  return (
    <Button
      size='lg'
      color='primary'
      type='submit'
      wFull
      justify='center'
      flex={true}
      {...rest}
      className={classNames('mt-2', { 'rounded-full': rounded }, rest.className)}
    />
  );
};
