'use client';
import React from 'react';
import AuthenticationLayout, {
  AuthenticationLayoutProps
} from '#/packages/authentication/components/AuthenticationLayout';
import { Loader, Spinner } from '#/packages/ui';

export interface AuthLoadingProps extends Omit<AuthenticationLayoutProps, 'children'> {
  msg?: AuthLoadingMessageProps['msg'];
}

export const AuthLoading: React.FC<AuthLoadingProps> = ({ msg, ...props }) => (
  <AuthenticationLayout {...props}>
    <AuthLoadingMessage msg={msg} />
  </AuthenticationLayout>
);

export interface AuthLoadingMessageProps {
  msg?: string;
}

export const AuthLoadingMessage: React.FC<AuthLoadingMessageProps> = ({ msg = 'Loading...' }) => (
  <div className={'flex flex-col flex-1 justify-center items-center gap-y-2 p-2 max-sm:pb-24'}>
    <Loader />
    <span className={'text-md font-medium text-gray-500'}>{msg}</span>
  </div>
);
