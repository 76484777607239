import React from 'react';
import { Button, ButtonProps } from '#/packages/ui';
import { GoogleLoginIcon } from '#/packages/ui/src/icons/GoogleLoginIcon';

export const GoogleButton = (props: ButtonProps) => (
  <Button
    color={'secondary'}
    weight={'outline'}
    size='xl'
    wFull
    flex
    justify='center'
    className='items-center'
    {...props}
  >
    {props.children || (
      <div className={'text-center'}>
        <span className='flex items-center gap-x-2'>
          <GoogleLoginIcon className='w-4 h-4' /> Continue with Google
        </span>
      </div>
    )}
  </Button>
);
